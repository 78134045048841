import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./css/ToolTipEditor.css";
import { useLocation, useNavigate } from "react-router-dom";
import { editTooltip, deleteTooltip } from "../services/api"; // Import deleteTooltip if needed
import ConfirmationModal from "./ConfirmationModal";

function TooltipEditor({ onSave, onClose, imageUrl, onDelete }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [tooltip, setTooltip] = useState({
    title: "",
    subtitle: "",
    description: "",
    links: ["", ""],
    innerImage: "",
  });
  const maxLength = 675;
  const [preview, setPreview] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [urlErrors, setUrlErrors] = useState(["", ""]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const urlPattern =
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;



  // React Quill modules and formats
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
  ];

  useEffect(() => {
    if (location && location.state && location.state.tooltip) {
      console.log("state data is", location.state.tooltip);
      setTooltip((prev) => ({
        ...prev,
        ...location.state.tooltip,
      }));
      setImageId(location.state.imageId);
    }
  }, [location ? location.state : " "]);

  useEffect(() => {
    const enableFullScreen = () => {
      const elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    };

    const handleOrientationChange = () => {
      enableFullScreen();
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const handleDeleteClick = () => {
    setShowConfirmModal(true); // Show confirmation modal
  };

  const confirmDelete = async () => {
    try {
      // Replace this with your delete API call
      console.log("tooltip data is", tooltip._id);

      await deleteTooltip(tooltip._id);
      onDelete(); // Callback to refresh or handle deletion
      onClose();  // Close the editor modal
      // navigate(`/edit/${imageId}`);
    } catch (error) {
      console.error("Failed to delete tooltip:", error);
    } finally {
      setShowConfirmModal(false); // Hide confirmation modal
      navigate(`/edit/${imageId}`);
    }
  };

  const isFormValid = () => {
    return (
      tooltip.title.trim() !== "" &&
      tooltip.subtitle.trim() !== "" &&
      tooltip.links.every((link) => link.trim() !== "") &&
      urlErrors.every((error) => error === "")
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (value) => {
    // Remove HTML tags to get the plain text length
    const plainText = value.replace(/<[^>]*>/g, "").replace(/\s+/g, " ");

    // Ensure that the text doesn't exceed the maxLength
    if (plainText.length <= maxLength) {
      setTooltip((prevTooltip) => ({
        ...prevTooltip,
        description: value, // Update state with the value (including HTML formatting)
      }));
    } else {
      // If it exceeds, trim the text to maxLength and reapply the formatting
      const allowedText = plainText.substring(0, maxLength);
      const formattedText = allowedText.replace(/\n/g, "<br/>"); // Reapply newlines with <br/> tag
      setTooltip((prevTooltip) => ({
        ...prevTooltip,
        description: formattedText, // Only store the allowed part of text with line breaks
      }));
    }
  };


  const handlePaste = (e) => {
    e.preventDefault();

    const pastedText = e.clipboardData.getData("text/plain"); // Get the pasted plain text
    const currentText = tooltip.description.replace(/<[^>]*>/g, ""); // Remove HTML tags from current text

    // Calculate the remaining characters that can be added
    const remainingLength = maxLength - currentText.length;
    const allowedText = pastedText.substring(0, remainingLength);

    // Preserve the formatting and append the allowed text
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
      description: prevTooltip.description + allowedText, // Append allowed text with formatting
    }));
  };;

  const handleKeyDown = (e) => {
    const plainText = tooltip.description.replace(/<[^>]*>/g, "").replace(/\s+/g, " ");
    if (plainText.length >= maxLength && e.key !== "Backspace" && e.key !== "Delete") {
      e.preventDefault(); // Prevent typing when limit is reached
    }
  };

  const handleUrlChange = (index, value) => {
    const newLinks = [...tooltip.links];
    newLinks[index] = value;
    setTooltip({ ...tooltip, links: newLinks });

    const errors = [...urlErrors];
    if (value && !urlPattern.test(value)) {
      errors[index] =
        "Please enter a valid URL in the format: https://www.websitename.com";
    } else {
      errors[index] = "";
    }
    setUrlErrors(errors);
  };

  const handleSave = async () => {
    if (isFormValid()) {
      setIsSaving(true);
      try {
        await onSave(tooltip);
      } finally {
        setIsSaving(false);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const handleEdit = async () => {
    if (!tooltip._id) {
      alert("Tooltip ID is missing");
      return;
    }

    if (!isFormValid()) {
      alert("Please fill in all required fields");
      return;
    }

    setIsSaving(true);

    try {
      const updatedTooltip = {
        title: tooltip.title,
        subtitle: tooltip.subtitle,
        description: tooltip.description,
        links: tooltip.links,
        innerImage: tooltip.innerImage,
      };

      await editTooltip(tooltip._id, updatedTooltip);
      navigate(`/edit/${imageId}`);
    } catch (error) {
      console.error("Error updating tooltip:", error);
      alert("Failed to update tooltip. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!tooltip._id) {
      alert("Tooltip ID is missing");
      return;
    }

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this tooltip?"
    );
    if (!confirmDelete) return;

    setIsSaving(true);

    try {
      await deleteTooltip(tooltip._id); // Call the delete API
      navigate(`/edit/${imageId}`); // Navigate back to the edit page
    } catch (error) {
      console.error("Error deleting tooltip:", error);
      alert("Failed to delete tooltip. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleInnerImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);

      const reader = new FileReader();
      reader.onloadend = () => {
        setTooltip({ ...tooltip, innerImage: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle} className="modalStyle">
        {/* Conditionally render the X button for handleEdit */}
        {tooltip.innerImage ? (
          <button style={closeButtonStyle} onClick={handleEdit}>
            <b>X</b>
          </button>
        ) : (
          <button style={closeButtonStyle} onClick={onClose}>
            <b>X</b>
          </button>
        )}
        <div className="tooltip-main">
          <div className="tooltip-form">
            {(tooltip.innerImage || imageUrl) && (
              <label htmlFor="imageUpload" className="upload-boxx">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleInnerImageChange}
                  id="imageUpload"
                  className="hidden-input"
                />
                {preview || tooltip.innerImage ? (
                  <img
                    src={preview || tooltip.innerImage}
                    alt="Preview"
                    style={{
                      maxWidth: "97%",
                      maxHeight: "440px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <div className="upload-placeholder">
                    <p>Drag and Drop image here</p>
                    <p>or</p>
                    <span>Upload from Computer</span>
                  </div>
                )}
              </label>
            )}
            <div className="tooltip-main-fields">
              <input
                className="tooltip-input"
                name="title"
                placeholder="Title"
                value={tooltip.title || ""}
                onChange={handleChange}
                style={{
                  fontSize: "1.5rem",
                  padding: 10,
                  width: "93%",
                }}
              />
              <input
                className="tooltip-input"
                name="subtitle"
                value={tooltip.subtitle || ""}
                placeholder="Subtitle"
                onChange={handleChange}
                style={{
                  fontSize: "0.8rem",
                  padding: 6,
                }}
              />
              {/* <textarea
                maxLength={250}
                className="tooltip-input tooltip-textarea"
                name="description"
                value={tooltip.description || ""}
                placeholder="Description"
                onChange={handleChange}
                style={{
                  fontSize: "0.8rem",
                  paddingBottom: "5rem",
                  lineHeight: "1.25",
                }}
              /> */}
              <ReactQuill
                value={tooltip.description || ""}
                onChange={handleDescriptionChange}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                modules={modules}
                placeholder="Description"
                style={{
                  fontSize: "0.8rem",
                  marginBottom: "2rem",
                }}
              />
              <small style={{ paddingTop: "10px" }}>
                {tooltip.description.replace(/<[^>]*>/g, "").length} / {maxLength} characters
              </small>
              <div className="tooltip-links">
                <div>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    Inquire
                  </label>
                  <input
                    key={0}
                    type="link"
                    className="tooltip-input"
                    value={tooltip.links[0] || ""}
                    placeholder="URL 1"
                    onChange={(e) => handleUrlChange(0, e.target.value)}
                  />
                  {urlErrors[0] && (
                    <span style={{ color: "red", fontSize: "0.8rem" }}>
                      {urlErrors[0]}
                    </span>
                  )}
                </div>
                <div>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    Contact Us
                  </label>
                  <input
                    key={1}
                    type="link"
                    className="tooltip-input"
                    value={tooltip.links[1] || ""}
                    placeholder="URL 2"
                    onChange={(e) => handleUrlChange(1, e.target.value)}
                  />
                  {urlErrors[1] && (
                    <span style={{ color: "red", fontSize: "0.8rem" }}>
                      {urlErrors[1]}
                    </span>
                  )}
                </div>
              </div>
              <div className="finishButton">
                {location && location.state && location.state.tooltip._id ? (
                  <>
                    {tooltip.innerImage || imageUrl ? (
                      <button
                        className="save-tooltip-btn delete-btn"
                        onClick={handleDeleteClick}
                        disabled={isSaving}
                      >
                        {isSaving ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    ) : (
                      <button
                        className={`save-tooltip-btn ${!isFormValid() ? "disabled" : ""
                          }`}
                        onClick={handleEdit}
                        disabled={!isFormValid() || isSaving}
                        title={
                          !isFormValid()
                            ? "Please fill in all required fields"
                            : ""
                        }
                      >
                        {isSaving ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Updating"
                        )}
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className={`save-tooltip-btn ${!isFormValid() ? "disabled" : ""
                      }`}
                    onClick={handleSave}
                    disabled={!isFormValid() || isSaving}
                    title={
                      !isFormValid() ? "Please fill in all required fields" : ""
                    }
                  >
                    {isSaving ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}
                {!isFormValid() && (
                  <span className="tooltip-text">
                    Please fill in all required fields
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this tooltip?"
          onConfirm={confirmDelete}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
}

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflowY: "auto",
};

const modalContentStyle = {
  backgroundColor: "white",
  borderRadius: "8px",
  width: "70%",
  maxWidth: "1100px",
  height: "85vh",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  "@media (max-width: 768px)": {
    width: "70%",
    height: "auto",
    padding: "15px",
  },
  "@media (max-width: 480px)": {
    borderRadius: "4px",
    padding: "10px",
  },
};

const closeButtonStyle = {
  position: "absolute",
  top: "5px",
  right: "5px",
  cursor: "pointer",
  backgroundColor: "green",
  borderRadius: "50%",
  color: "white",
  border: "none",
  fontWeight: "bold",
  fontSize: "20px",
  fontFamily: "Archivo",
  width: "40.5px",
  height: "40.5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default TooltipEditor;