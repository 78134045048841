// frontend/src/pages/Home.js
import React, { Suspense, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteMultipleImages, getAllImages } from "../services/api";
import "./css/Home.css";
import LazyImage from "../components/LazyImage";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../redux/authSlice";
import Swal from "sweetalert2";


function Home() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);

  const [images, setImages] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const accessToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function fetchImages() {
      console.log("access token is", accessToken);

      const data = await getAllImages({ accessToken });
      setImages(data);
    }
    fetchImages();
  }, []);

  const handleShare = (imageId) => {
    navigate(`/share/${imageId}`);
  };

  const toggleSelectionMode = () => {
    setIsSelectionMode(!isSelectionMode);
    setSelectedImages([]);
  };

  const toggleImageSelection = (imageId) => {
    setSelectedImages((prev) =>
      prev.includes(imageId)
        ? prev.filter((id) => id !== imageId)
        : [...prev, imageId]
    );
  };

  // const handleDeleteSelected = async () => {
  //   if (window.confirm("Are you sure you want to delete selected images?")) {
  //     try {
  //       await deleteMultipleImages(selectedImages);
  //       // Refresh images after deletion
  //       const data = await getAllImages({accessToken});
  //       setImages(data);
  //       setSelectedImages([]);
  //       setIsSelectionMode(false);
  //     } catch (error) {
  //       console.error("Error deleting images:", error);
  //     }
  //   }
  // };


  const handleDeleteSelected = async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });
  
      if (result.isConfirmed) {
        await deleteMultipleImages(selectedImages);
        const data = await getAllImages({accessToken});
        setImages(data);
        setSelectedImages([]);
        setIsSelectionMode(false);
        
        Swal.fire({
          title: "Deleted!",
          text: "Your images have been deleted.",
          icon: "success"
        });
      }
    } catch (error) {
      console.error("Error deleting images:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!"
      });
    }
  };

  
  
  return (
    <>
      {/* <header className="home-page-header">
        <span style={{ color: "grey" }}>Home</span>
      </header> */}
      <header className="home-page-header">
        <span style={{ color: "grey" }}>Home</span>
        <button
          onClick={() => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            // localStorage.removeItem('username');
            localStorage.removeItem("userEmail");
            dispatch(clearToken());
            navigate("/");
          }}
          style={{
            backgroundColor: "black",
            color: "white",
            borderRadius: "10px",
            padding: "8px 16px",
            border: "none",
            cursor: "pointer",
          }}
        >
          Logout
        </button>
      </header>

      <div className="home-page">
        <nav className="navbar">
          {location.pathname !== "/home" && (
            <Link
              to="/home"
              className="btn-edit"
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
                transition: "background-color 0.3s ease",
              }}
            >
              Home
            </Link>
          )}
          {location.pathname !== "/upload" && (
            <Link
              to="/upload"
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
                transition: "background-color 0.3s ease",
              }}
            >
              Upload
            </Link>
          )}

          <button
            onClick={toggleSelectionMode}
            style={{
              backgroundColor: isSelectionMode ? "red" : "black",
              color: "white",
              borderRadius: "10px",
              transition: "background-color 0.3s ease",
            }}
          >
            {isSelectionMode ? "Cancel" : "Delete"}
          </button>
          {isSelectionMode && selectedImages.length > 0 && (
            <button
              onClick={handleDeleteSelected}
              style={{
                backgroundColor: "red",
                color: "white",
                borderRadius: "10px",
                transition: "background-color 0.3s ease",
              }}
            >
              Delete Selected ({selectedImages.length})
            </button>
          )}
        </nav>
        <h1 style={{ color: "grey" }}>Gallery</h1>
        <div className="card-grid">
          {images.map((image) => (
            <div
              key={image._id}
              className={`card ${
                selectedImages.includes(image._id) ? "selected" : ""
              }`}
              // className="card"
              onClick={() => isSelectionMode && toggleImageSelection(image._id)}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isSelectionMode && (
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    zIndex: 2,
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedImages.includes(image._id)}
                    onChange={() => toggleImageSelection(image._id)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              )}

              <div
                style={{
                  padding: "0px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                {image.projectName || "Untitled Project"}
              </div>
              <Link to={`/edit/${image._id}`}>
                <Suspense fallback={<div className="image-placeholder" />}>
                  <LazyImage
                    src={`${image.url}`}
                    alt="Uploaded"
                    style={{
                      width: "100%",
                      height: "10rem",
                      objectFit: "contain",
                      borderRadius: "8px",
                      marginTop: "0.5rem",
                    }}
                  />
                </Suspense>
              </Link>

              {/* Display tooltip indicator */}
              {image.tooltips &&
                image.tooltips.map((tooltip, i) => (
                  <div
                    key={i}
                    onMouseEnter={() => setHoveredImage(image._id)}
                    onMouseLeave={() => setHoveredImage(null)}
                    style={{
                      color: "green",
                      position: "absolute",
                      top: "79%",
                      right: "48%",
                      cursor: "pointer",
                      zIndex: 10,
                    }}
                  >
                    <span className="material-symbols-outlined">
                      radio_button_checked
                    </span>
                    {hoveredImage === image._id && (
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "grey",
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          top: "-30px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {image.tooltips.length} Tooltip
                        {image.tooltips.length !== 1 ? "s" : ""}
                      </div>
                    )}
                  </div>
                ))}

              {/* Share and Edit Buttons */}
              <div className="image-buttons">
                <Link to={`/edit/${image._id}`}>
                  <button className="btn-edit">Edit</button>
                </Link>
                {/* <Link to={`/share/${image._id}`}>
                  <button
                    className="btn-share"
                    style={{
                      backgroundColor: image.finished && image.finished === true ? "green" : "gray",
                      color: "white",
                    }}
                  >
                    Share
                  </button>
                </Link> */}
                <Link to={image.finished ? `/share/${image._id}` : "#"}>
                  <button
                    className="btn-share"
                    style={{
                      backgroundColor: image.finished ? "green" : "gray",
                      color: "white",
                    }}
                    disabled={!image.finished} // Disables button if image.finished is not true
                    onClick={(e) => {
                      if (!image.finished) e.preventDefault(); // Prevents navigation if not finished
                    }}
                  >
                    Share
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="footer">
          <footer>
            <h5>© 2024 All rights reserved by Mike Faircloth.</h5>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Home;
