import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TooltipEditor from "../components/ToolTipEditor";
import TooltipModal from "../components/TooltipModal";
import Draggable from "react-draggable";
import CircularProgress from "@mui/material/CircularProgress";
import {
  addTooltip,
  getImageWithTooltips,
  updateFinishDetails,
  updateImageDetails,
  updateTooltipPosition,
} from "../services/api"; // Import API function
import "./css/EditImage.css";

function EditImage() {
  const { imageId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [keyboardClick, setKeyboardClick] = useState("");
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [activeDragTooltip, setActiveDragTooltip] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragCoordinates, setDragCoordinates] = useState({ x: 0, y: 0 });
  const [currentImage, setCurrentImage] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedTooltip, setSelectedTooltip] = useState(null);
  const [isFinishLoading, setIsFinishLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [projectName, setProjectName] = useState(""); // State for project name
  const [stylePreferences, setStylePreferences] = useState({
    iconStyle: "",
    size: "",
    color: "",
  });

  useEffect(() => {
    const fetchImage = async () => {
      const updatedImage = await getImageWithTooltips(imageId);
      setCurrentImage(updatedImage);
      setProjectName(updatedImage.projectName || ""); // Initialize project name
      setStylePreferences({
        iconStyle: updatedImage.iconStyle || "",
        size: updatedImage.size || "",
        color: updatedImage.color || "",
      });
    };
    fetchImage();
  }, [imageId]);

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setMousePosition({ x, y });
  };

  const handleDrag = (e, data, tooltip) => {
    setIsDragging(true);
    const imageContainer = e.target.closest("div");
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    console.log({ x, y });
    setActiveDragTooltip(tooltip._id);
    setDragCoordinates({ x: x, y: y });
  };

  const handleSaveDetails = async () => {
    setIsSaveLoading(true);
    try {
      await updateImageDetails(imageId, { projectName, stylePreferences });
      const updatedImage = await getImageWithTooltips(imageId);
      setCurrentImage(updatedImage);
      navigate("/home");
    } finally {
      setIsSaveLoading(false);
    }
  };

  const handleFinishDetails = async () => {
    setIsFinishLoading(true);
    try {
      await updateFinishDetails(imageId, { finished: true });
      const updatedImage = await getImageWithTooltips(imageId);
      setCurrentImage(updatedImage);
      navigate("/home");
    } finally {
      setIsFinishLoading(false);
    }
  };

  const handleImageClick = (e) => {
    setKeyboardClick("editor");
    // if (!selectedTooltip) {
    const image = e.target;
    const { offsetX, offsetY } = e.nativeEvent;
    console.log(image, offsetX, offsetY);

    // Calculate percentage of the position relative to image size
    const xPercent = (offsetX / image.width) * 100;
    const yPercent = (offsetY / image.height) * 100;

    console.log(xPercent, yPercent);

    setSelectedPosition({ x: xPercent, y: yPercent });
    // }
  };

  const handleTooltipSave = async (tooltipData) => {
    try {
      // Change this line
      await addTooltip(currentImage._id, {
        x: selectedPosition.x,
        y: selectedPosition.y,
        ...tooltipData,
      });

      const updatedImage = await getImageWithTooltips(currentImage._id);
      setCurrentImage(updatedImage);
      setKeyboardClick("");
      setSelectedPosition(null);
    } catch (error) {
      console.log("Error saving tooltip:", error);
    }
  };

  const handleTooltipClick = (e, tooltip) => {
    e.stopPropagation();
    setSelectedTooltip(tooltip);
  };

  const handleCloseEditor = () => {
    setKeyboardClick("");
    setSelectedPosition(null);
  };

  const handleCloseModal = () => {
    setKeyboardClick("");
    setSelectedTooltip(null);
  };

  const handleDragStop = async (e, data, tooltip) => {
    if (!isDragging) return;
    // console.log("isDragging:", isDragging);

    setKeyboardClick("");

    // Use e.target to get the div element that contains the image
    const div = e.target.closest("div"); // Ensure you're getting the parent div, not the image

    // Get the bounding client rect of the div to get its position relative to the viewport
    const divRect = div.getBoundingClientRect();
    const divWidth = divRect.width;
    const divHeight = divRect.height;
    const divLeft = divRect.left;
    const divTop = divRect.top;

    // Log for debugging to see the actual position
    // console.log("Div width:", divWidth);
    // console.log("Div height:", divHeight);
    // console.log("Tooltip dragged to position:", data.x, data.y);

    // Calculate new positions relative to the div (by subtracting div's left and top positions)
    const newX = data.x - divLeft; // x position relative to the div
    const newY = data.y - divTop; // y position relative to the div

    // Log the new position of the tooltip relative to the div
    // console.log("Tooltip position relative to div:", newX, newY);

    // Now convert these pixel positions into percentages relative to the div's dimensions
    const xPercent = (newX / divWidth) * 100; // Calculate the percentage of the div's width
    const yPercent = (newY / divHeight) * 100; // Calculate the percentage of the div's height

    // Log the position of the tooltip as a percentage
    // console.log("Tooltip position in percentage:", xPercent, yPercent);
    // Create an updated tooltip object with the percentage-based position
    const updatedTooltip = {
      ...tooltip,
      x: dragCoordinates.x, // Store the x position as a percentage
      y: dragCoordinates.y, // Store the y position as a percentage
      iconStyle: stylePreferences.iconStyle || "radio_button_checked",
      color: stylePreferences.color || "black",
      size: stylePreferences.size || "large",
    };

    // console.log(
    //   "drag coordinates are:",
    //   typeof updatedTooltip.x,
    //   updatedTooltip.x,
    //   updatedTooltip.y
    // );
    if (updatedTooltip.x > 0 && updatedTooltip.y > 0) {
      // Call the API to update the tooltip's position in the database
      await updateTooltipPosition(tooltip._id, updatedTooltip);
      setCurrentImage((prev) => ({
        ...prev,
        tooltips: prev.tooltips.map((t) =>
          t._id === tooltip._id ? updatedTooltip : t
        ),
      }));
      setSelectedTooltip(updatedTooltip);
      setDragCoordinates({ x: 0, y: 0 });
    }
  };

  useEffect(() => {
    const updateStyles = async () => {
      if (currentImage && currentImage.tooltips) {
        const updatedTooltips = await Promise.all(
          currentImage.tooltips.map(async (tooltip) => {
            const updatedTooltip = {
              ...tooltip,
              iconStyle: stylePreferences.iconStyle || "radio_button_checked",
              color: stylePreferences.color || "black",
              size: stylePreferences.size || "large",
            };
            await updateTooltipPosition(tooltip._id, updatedTooltip);
            return updatedTooltip;
          })
        );
        setCurrentImage((prev) => ({ ...prev, tooltips: updatedTooltips }));
      }
    };
    updateStyles();
  }, [stylePreferences]);

  const refreshTooltips = async () => {
    const updatedImage = await getImageWithTooltips(imageId);
    setCurrentImage(updatedImage);
  };

  const handleTooltipClickOnce = async (e, tooltip) => {
    e.stopPropagation();
    // console.log("tooltip clicked:", tooltip);

    const currentTooltip = currentImage.tooltips.find(
      (t) => t._id === tooltip._id
    );
    setSelectedTooltip(currentTooltip);
    if (selectedTooltip && selectedTooltip._id === tooltip._id) {
      const updatedTooltip = {
        ...tooltip,
        x: selectedTooltip.x, // Store the x position as a percentage
        y: selectedTooltip.y, // Store the y position as a percentage
        iconStyle: stylePreferences.iconStyle || "radio_button_checked",
        color: stylePreferences.color || "black",
        size: stylePreferences.size || "large",
      };

      if (updatedTooltip.x > 0 && updatedTooltip.y > 0) {
        // Call the API to update the tooltip's position in the database
        await updateTooltipPosition(tooltip._id, updatedTooltip);
        setCurrentImage((prev) => ({
          ...prev,
          tooltips: prev.tooltips.map((t) =>
            t._id === tooltip._id ? updatedTooltip : t
          ),
        }));
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!selectedTooltip) return;

      const currentPosition = currentImage.tooltips.find(
        (t) => t._id === selectedTooltip._id
      );
      let { x, y } = currentPosition;
      const movementStep = 0.1; // Adjust step size for percentage movement

      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          y = Math.max(0, y - movementStep);
          break;
        case "ArrowDown":
          e.preventDefault();
          y = Math.min(100, y + movementStep);
          break;
        case "ArrowLeft":
          e.preventDefault();
          x = Math.max(0, x - movementStep);
          break;
        case "ArrowRight":
          e.preventDefault();
          x = Math.min(100, x + movementStep);
          break;
        default:
          return;
      }

      const updatedTooltip = { ...selectedTooltip, x, y };

      // Update selectedTooltip and currentImage state
      setSelectedTooltip(updatedTooltip);
      setCurrentImage((prev) => ({
        ...prev,
        tooltips: prev.tooltips.map((t) =>
          t._id === selectedTooltip._id ? updatedTooltip : t
        ),
      }));

      // Optionally persist the new position
      updateTooltipPosition(selectedTooltip._id, { x, y });
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedTooltip]);

  return (
    <div>
      <div>
        <nav className="navbar">
          {location.pathname !== "/home" && (
            <Link
              to="/home"
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
              }}
            >
              Home
            </Link>
          )}
          {location.pathname !== "/upload" && (
            <Link
              to="/upload"
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
              }}
            >
              Upload
            </Link>
          )}
        </nav>
      </div>
      <div className="mainWrap">
        <div
          className="editImageMain"
          onDoubleClick={() => {
            setKeyboardClick("editor");
          }}
        >
          <div className="editTop">
            <input
              type="text"
              placeholder="Enter Project Name Here"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              style={{
                padding: "0.75rem",
                border: "none",
                width: "100%",
                maxWidth: "20rem",
                minWidth: "1rem",
                overflow: "hidden",
                fontSize: "1.5rem",
                textAlign: "left",
              }}
            />
          </div>
          {currentImage && (
            <div
              onMouseMove={(e) => {
                if (isDragging && activeDragTooltip) {
                  const tooltip = currentImage.tooltips.find(
                    (t) => t._id === activeDragTooltip
                  );
                  handleDrag(e, null, tooltip);
                }
              }}
              onDoubleClick={(e) => {
                handleImageClick(e);
                setKeyboardClick("editor");
              }}
              style={{
                display: "inline-block",
                width: "100%",
                height: "auto",
                position: "relative",
              }}
            >
              <img
                src={`${currentImage.url}`}
                alt="Uploaded"
                style={{
                  width: "100%",
                  //   maxHeight: "80vh",
                  objectFit: "contain",
                  display: "block",
                  //   borderRadius: "1rem",
                  //   border:"1px solid red",
                  //   marginTop: "1rem",
                  cursor: "pointer",
                }}
              />

              {currentImage.tooltips.map((tooltip, i) => (
                <Draggable
                  bounds="parent"
                  //   positionOffset={{ x: -55, y: -55 }}
                  key={tooltip._id}
                  //   defaultPosition={{ x: tooltip.x, y: tooltip.y }}
                  position={{
                    x: `${tooltip.x}%`, // Ensure x is between 0 and 100
                    y: `${tooltip.y}%`, // Ensure y is between 0 and 100
                  }}
                  //   position={{
                  //     x: Math.min(Math.max(0, tooltip.x), 100), // Ensure x is between 0 and 100
                  //     y: Math.min(Math.max(0, tooltip.y), 100), // Ensure y is between 0 and 100
                  //   }}
                  //   onDrag={(e, data) => handleDrag(e, data, tooltip)}
                  onDrag={() => {
                    setIsDragging(true);
                    setActiveDragTooltip(tooltip._id);
                  }}
                  // onStart={(e) => handleTooltipClickOnce(e, tooltip)}
                  onStop={(e, data) => {
                    setIsDragging(false);
                    setActiveDragTooltip(null);
                    handleDragStop(e, data, tooltip);
                  }}
                  //   onStop={(e, data) => handleDragStop(e, data, tooltip)}
                >
                  <div
                    className="tooltipp"
                    key={i}
                    onClick={(e) => {
                      handleTooltipClickOnce(e, tooltip);
                      setKeyboardClick("keyboard");
                    }}
                    onDoubleClick={(e) => {
                      handleTooltipClick(e, tooltip);
                      setKeyboardClick("tooltip");
                    }}
                    style={{
                      position: "absolute",
                      left: `${tooltip.x}%`, // Convert percentage to pixel-based positioning
                      top: `${tooltip.y}%`, // Convert percentage to pixel-based positioning
                      // fontSize: '1.5rem',
                      color: stylePreferences.color || "black",
                      cursor: "pointer",
                      // transform: "translate(-50%, -50%)",
                      width: "fit-content",
                      height: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize:
                          stylePreferences.size === "small"
                            ? "0.75vw"
                            : stylePreferences.size === "medium"
                            ? "1vw"
                            : stylePreferences.size === "large"
                            ? "1.25vw"
                            : "1.25vw",
                      }}
                      className="material-symbols-outlined"
                    >
                      {stylePreferences.iconStyle || "radio_button_checked"}
                    </span>
                    {/* Add coordinate display */}
                    {isDragging &&
                      activeDragTooltip === tooltip._id &&
                      dragCoordinates.x !== 0 &&
                      dragCoordinates.y !== 0 && (
                        <div
                          style={{
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                            // background: stylePreferences.color || "black",
                            background: "black",
                            color: "white",
                            padding: "4px 8px",
                            borderRadius: "4px",
                            fontSize: "12px",
                          }}
                        >
                          x: {Math.round(dragCoordinates.x)} y:{" "}
                          {Math.round(dragCoordinates.y)}
                        </div>
                      )}
                  </div>
                </Draggable>
              ))}
            </div>
          )}

          {keyboardClick === "editor" && (
            <TooltipEditor
              onSave={handleTooltipSave}
              onClose={handleCloseEditor}
              imageUrl={`${currentImage.url}`}
              imageId={imageId}
              onDelete={refreshTooltips}
            />
          )}
          {selectedTooltip && keyboardClick === "tooltip" && (
            <TooltipModal
              tooltip={selectedTooltip}
              imageUrl={`${currentImage.url}`}
              onClose={handleCloseModal}
              onDelete={refreshTooltips}
              imageId={imageId}
              isEditMode={true}
            />
          )}
          <div className="editBottom">
            <button
              className="finish-button"
              onClick={handleFinishDetails}
              disabled={isFinishLoading}
            >
              {isFinishLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Finish"
              )}
            </button>
            <button
              className="save-button"
              onClick={handleSaveDetails}
              disabled={isSaveLoading}
            >
              {isSaveLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </button>
          </div>
          <div className="tooltip-options">
            <div className="radio-group">
              <label>
                <b>Choose Hotspot Style:</b>
              </label>
              <div className="icon-options">
                <span
                  className="material-symbols-outlined"
                  onClick={() =>
                    setStylePreferences({
                      ...stylePreferences,
                      iconStyle: "radio_button_checked",
                    })
                  }
                  style={{
                    cursor: "pointer",
                    color:
                      stylePreferences.iconStyle === "radio_button_checked"
                        ? "black"
                        : "grey",
                  }}
                >
                  radio_button_checked
                </span>
                <span
                  className="material-symbols-outlined"
                  onClick={() =>
                    setStylePreferences({
                      ...stylePreferences,
                      iconStyle: "radio_button_unchecked",
                    })
                  }
                  style={{
                    cursor: "pointer",
                    color:
                      stylePreferences.iconStyle === "radio_button_unchecked"
                        ? "black"
                        : "grey",
                  }}
                >
                  radio_button_unchecked
                </span>
              </div>
            </div>
            <div className="radio-group">
              <label>
                <b>Choose Hotspot Size:</b>
              </label>
              <div className="radio-options">
                {["small", "medium", "large"].map((size) => (
                  <label key={size}>
                    <input
                      type="radio"
                      name="size"
                      value={size}
                      checked={stylePreferences.size === size}
                      onChange={() =>
                        setStylePreferences({ ...stylePreferences, size })
                      }
                    />
                    {size.charAt(0).toUpperCase() + size.slice(1)}
                  </label>
                ))}
              </div>
            </div>
            <div className="color-group">
              <label>
                <b>Choose Hotspot Color:</b>
              </label>
              <div className="color-options">
                {["black", "grey", "lightgreen"].map((color) => (
                  <button
                    key={color}
                    className={`color-button ${
                      stylePreferences.color === color ? "selected" : ""
                    }`}
                    style={{
                      backgroundColor: color,
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      margin: "5px",
                    }}
                    onClick={() =>
                      setStylePreferences({ ...stylePreferences, color })
                    }
                  />
                ))}
                <input
                  type="color"
                  value={stylePreferences.color}
                  onChange={(e) =>
                    setStylePreferences({
                      ...stylePreferences,
                      color: e.target.value,
                    })
                  }
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    margin: "5px",
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <p>
              Double click on image to place hotspot, then double click on the
              hot spot to view it.
            </p>
            <p>
              <b>Click on and drag to move the hotspots.</b>
            </p>
          </div>
          <div className="footer">
            <footer>
              <h5>© 2024 All rights reserved by Mike Faircloth.</h5>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditImage;
// ------------------------------------------------------------------------
