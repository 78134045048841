import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getImageWithTooltips } from "../services/api";
import TooltipModal from "./TooltipModal";
import screenfull from "screenfull";
import "./css/EmbeddedView.css";

function EmbeddedView() {
  const { imageId } = useParams();
  const [image, setImage] = useState(null);
  const [selectedTooltip, setSelectedTooltip] = useState(null);
  const [showRotateMessage, setShowRotateMessage] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = React.useRef(null);
  const [hasShownRotateMessage, setHasShownRotateMessage] = useState(false);
  const [portraitCount, setPortraitCount] = useState(0);



  useEffect(() => {
    const fetchImage = async () => {
      const data = await getImageWithTooltips(imageId);
      setImage(data);
    };
    fetchImage();

    const handleFullscreenChange = () => {
      setIsFullscreen(
        !!document.fullscreenElement || !!document.webkitFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange); // For Safari

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
    };
  }, [imageId]);

  const handleTooltipClick = (e, tooltip) => {
    e.stopPropagation();
    setSelectedTooltip(tooltip);
  };
  // const toggleFullscreen = () => {
  //   if (!isFullscreen) {
  //     if (containerRef.current.requestFullscreen) {
  //       containerRef.current.requestFullscreen();
  //     } else if (containerRef.current.webkitRequestFullscreen) {
  //       containerRef.current.webkitRequestFullscreen(); // For Safari
  //     } else if (containerRef.current.msRequestFullscreen) {
  //       containerRef.current.msRequestFullscreen(); // IE/Edge
  //     } else {
  //       console.error("Fullscreen API is not supported on this browser.");
  //     }
  //   } else {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } else if (document.webkitExitFullscreen) {
  //       document.webkitExitFullscreen(); // For Safari
  //     }
  //   }
  // };
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Enter fullscreen
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen(); // For Safari
      } else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen(); // For IE/Edge
      } else {
        console.error("Fullscreen API is not supported on this browser.");
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // For Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // For IE/Edge
      } else {
        console.error("Fullscreen exit is not supported on this browser.");
      }
    }
  };
  
  useEffect(() => {
    const checkOrientation = () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  
      if (isMobile && !hasShownRotateMessage) {
        setShowRotateMessage(isPortrait);
      }
    };
  
    const handleOrientationChange = () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile && !hasShownRotateMessage) {
        setHasShownRotateMessage(true); // Mark as shown after first rotation
        setShowRotateMessage(false); // Hide the message after rotation
      }
    };
  
    // Initial check
    checkOrientation();
  
    // Add event listeners
    window.addEventListener("resize", checkOrientation);
    window.addEventListener("orientationchange", handleOrientationChange);
  
    return () => {
      window.removeEventListener("resize", checkOrientation);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, [hasShownRotateMessage]);
  
  const handleCloseRotateMessage = () => {
    setShowRotateMessage(false);
    setHasShownRotateMessage(true); // Ensure it won't show again until a refresh
  };
  
  if (!image) return <p>Loading...</p>;


  return (
    <div
      className="embedded-container"
      style={{
        backgroundColor:"white",
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
      ref={containerRef}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "100vw",
          maxHeight: "100vh",
        }}
      >
        <button
        className="fullscreen-button" 
          onClick={toggleFullscreen}
    
          aria-label={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        >
          {isFullscreen ? (
            // Exit Fullscreen SVG Icon
            <svg
  height="2rem"
  width="2rem"
  version="1.1"
  id="_x32_"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 512 512"
  xmlSpace="preserve"
>
  <g>
    <polygon
      fill="#000000"
      points="461.212,314.349 314.342,314.349 314.342,461.205 357.596,417.973 451.591,511.985 512,451.599 417.973,357.581"
    />
    <polygon
      fill="#000000"
      points="50.788,197.667 197.659,197.667 197.659,50.797 154.42,94.043 60.394,0.025 0,60.417 94.027,154.428"
    />
    <polygon
      fill="#000000"
      points="94.035,357.588 0.016,451.599 60.394,511.992 154.42,417.973 197.668,461.205 197.668,314.349 50.788,314.349"
    />
    <polygon
      fill="#000000"
      points="417.99,154.428 512,60.401 451.591,0.008 357.58,94.035 314.342,50.797 314.342,197.651 461.212,197.651"
    />
  </g>
</svg>
          ) : (
            // Enter Fullscreen SVG Icon
            <svg
  height="2rem"
  width="2rem"
  version="1.1"
  id="_x32_"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 512 512"
  xmlSpace="preserve"
>
  <g>
    <polygon
      fill="#000000"
      points="345.495,0 394.507,49.023 287.923,155.607 356.384,224.086 462.987,117.493 511.991,166.515 511.991,0"
    />
    <polygon
      fill="#000000"
      points="155.615,287.914 49.022,394.507 0.009,345.494 0.009,512 166.515,512 117.493,462.978 224.087,356.375"
    />
    <polygon
      fill="#000000"
      points="356.384,287.914 287.923,356.375 394.507,462.978 345.495,512 511.991,512 511.991,345.485 462.977,394.507"
    />
    <polygon
      fill="#000000"
      points="166.505,0 0.009,0 0.009,166.506 49.022,117.493 155.615,224.086 224.087,155.607 117.501,49.023"
    />
  </g>
</svg>
          )}
        </button>
        <img
          src={image.url}
          alt="Shared"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            display: "block",
          }}
        />
        {image.tooltips?.map((tooltip, i) => (
          <div
            key={i}
            onClick={(e) => handleTooltipClick(e, tooltip)}
            style={{
              color: `${image.color}` || "black",
              position: "absolute",
              left: `${tooltip.x}%`,
              top: `${tooltip.y}%`,
              cursor: "pointer",
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{
                fontSize:
                  image.size === "small"
                    ? "0.75vw"
                    : image.size === "medium"
                    ? "1vw"
                    : image.size === "large"
                    ? "1.25vw"
                    : "1.25vw",
              }}
            >
              {image.iconStyle || "radio_button_checked"}
            </span>
          </div>
        ))}
        {selectedTooltip && (
          <TooltipModal
            tooltip={selectedTooltip}
            imageUrl={image.url}
            onClose={() => setSelectedTooltip(null)}
            isEditMode={false}
          />
        )}
      </div>
      {showRotateMessage && (
  <div style={{
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  }}>
    <button 
      onClick={handleCloseRotateMessage}
      style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        background: 'none',
        border: 'none',
        color: 'white',
        fontSize: '24px',
        cursor: 'pointer'
      }}
    >
      ✕
    </button>
    <span
      className="material-symbols-outlined rotate-animation"
      style={{
        fontSize: "48px",
        marginBottom: "16px",
      }}
    >
      screen_rotation
    </span>
    <p>Please rotate your device</p>
  </div>
)}
    </div>
  );
}

export default EmbeddedView;
