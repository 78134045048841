// frontend/src/services/api.js
import axios from 'axios';
// import { useSelector } from 'react-redux';
const API_URL = 'https://360lovelife.com/api/images';
// const API_URL = 'http://localhost:3001/api/images';

const accessToken = localStorage.getItem('accessToken');


// export const uploadImage = async (file) => {
//     const formData = new FormData();
//     formData.append('image', file);
//     const response = await axios.post(`${API_URL}/upload`, formData);
//     return response.data.image;
// };


// export const uploadImage = async (file) => {
//     const formData = new FormData();
//     formData.append('image', file);
//     const response = await axios.post(`${API_URL}/upload`, formData, {
//         headers: {
//             'Authorization': `Bearer ${accessToken}`,
//             'Content-Type': 'multipart/form-data'
//         }
//     });
//     if(response){

//         return response.data.image;
//     }else {
//         return "error"
//     }
// };

// const accessToken = useSelector((state) => state.auth.token);
export const uploadImage = async (file) => {
    // Extract metadata from the file
    const originalname = file.name;
    const mimetype = file.type;
    const size = file.size;
    console.log(localStorage.getItem('accessToken'))
    // Step 1: Send metadata to the backend
    const metadataResponse = await axios.post(`${API_URL}/upload`, {
        originalname,
        mimetype,
        size,
    }, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!metadataResponse || !metadataResponse.data.uploadUrl) {
        throw new Error("Failed to get upload URL from backend");
    }

    const { uploadUrl, image } = metadataResponse.data;

    // Step 2: Upload the file to S3 using the pre-signed URL
    await axios.put(uploadUrl, file, {
        headers: {
            'Content-Type': mimetype, // Ensure the correct content type
        },
    });

    // Return the image details saved in the backend
    return image;
};



// export const addTooltip = async (imageId, tooltip) => {
//     const response = await axios.post(`${API_URL}/tooltip`, { imageId, tooltip });
//     return response.data.image;
// };

export const addTooltip = async (imageId, tooltip) => {
    // If `tooltip.innerImage` is a file, convert it to Base64
    if (tooltip.innerImage instanceof File) {
        const base64Image = await convertFileToBase64(tooltip.innerImage);
        tooltip.innerImage = base64Image; // Replace the file with the Base64 string
    }
    // console.log("tooltip is:", tooltip);
    

    // Send the request to the backend
    const response = await axios.post(`${API_URL}/tooltip`, { imageId, tooltip });
    return response.data.image;
};

// Helper function to convert a file to Base64
const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};


export const getImageWithTooltips = async (imageId) => {
    const response = await axios.get(`${API_URL}/${imageId}`);
    return response.data.image;
};

export const getAllImages = async ({accessToken}) => {
    const response = await axios.get(API_URL, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return response.data.images;
};

export const getImage = async (imageId) => {
    const response = await axios.get(`${API_URL}/${imageId}`);
    return response.data.image;
};

// export const updateImageDetails = async (imageId, details) => {
//     await axios.put(`${API_URL}/${imageId}/details`, details);
// };

export const updateImageDetails = async (imageId, details) => {
    await axios.put(`${API_URL}/${imageId}/details`, details, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
};

export const updateFinishDetails = async (imageId, details) => {
    await axios.put(`${API_URL}/${imageId}/finish`, details);
};


export const updateTooltipPosition = async (tooltipId, { x, y, iconStyle, color, size }) => {
    const response = await axios.put(`${API_URL}/tooltips/${tooltipId}`, { x, y, iconStyle, color, size });
    return response.data;
};

export const deleteMultipleImages = async (imageIds) => {
    const response = await axios.delete(`${API_URL}/bulk-delete`, {
        data: { imageIds },
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return response.data;
}

export const editTooltip = async (tooltipId, updatedTooltip) => {
    const response = await axios.put(
        `${API_URL}/tooltip/edit/${tooltipId}`,
        updatedTooltip,
        {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }
    );
    return response.data.tooltip;
};

export const deleteTooltip = async (tooltipId) => {
    const response = await axios.delete(
        `${API_URL}/tooltip/delete/${tooltipId}`,
        {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
};
