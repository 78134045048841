import React from 'react';

function ConfirmationModal({ message, onConfirm, onCancel }) {
    const modalStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1001,
    };

    const contentStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        width: '300px',
    };

    const buttonStyle = {
        margin: '10px',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    };

    return (
        <div style={modalStyle}>
            <div style={contentStyle}>
                <p>{message}</p>
                <div>
                    <button style={{ ...buttonStyle, backgroundColor: '#dc3545', color: 'white' }} onClick={onConfirm}>
                        Yes
                    </button>
                    <button style={{ ...buttonStyle, backgroundColor: '#6c757d', color: 'white' }} onClick={onCancel}>
                        No
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;
